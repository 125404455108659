/*
Copyright @dhirac, Dashboard template is created by
Dhiraj Shrestha for Groupm Portugal Mediafy and can only
be used inside Groupm Network.
All Rights Reserved
*/

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&dispaly-swap');

:root{
    --main-color:#4169e1;
    --side-color:#2950c5;
    --color-dark:#1D2231;
    --text-grey:#8390A2;
}


*{
    padding: 0;
    margin:0;
    box-sizing: border-box;
    list-style-type: none;
    text-decoration: none;
    font-family: 'Poppins',sans-serif;
}

.sidebar{
    width: 255px;
    position: fixed;
    left: 0;
    top:0;
    height: 100%;
    background: var(--main-color);
    transition: width 300ms;
    
   
}

.table-cell-trucate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.sidebar-brand{
    height: 53px;
    padding-left: 1rem 0rem 1rem 2rem;
    color:#fff;
    background:var(--side-color);
    padding-top:.5rem
}

.sidebar-brand span{
    display: inline-block;
    padding-right: 1rem;
    display: flex;
    justify-content: center;
    text-align: center;

   

}

 .sidebar-brand h2 span:first-child{
    display: none;
}
.sidebar-menu{
    margin-top: 1rem;;

}

.sidebar-menu li {
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 2rem;
}


.sidebar-menu li:hover .sidebar-menu li ul{
    display: inline;
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 2rem;
}




.sidebar-menu li ul {
    display: none;
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 2rem;
}

.sidebar-menu a {
   
    display: block;
    color:#fff;
    font-size: 0.8rem;
    padding:1rem;

}

.sidebar-menu a.active{
    background: #fff;
    padding:1rem;
    color:var(--main-color);
    border-radius: 30px 0px 0px 30px;

}


.sidebar-menu a:hover{
    background: #fff;
    padding:1rem;
    color:var(--main-color);
    border-radius: 30px 0px 0px 30px;

}
.sidebar-menu a span:first-child{
    font-size: 1.2rem;
    padding-right: 1rem;
}


#nav-toggle:checked + .sidebar{
    width: 70px;
    display: none;
   

}


#nav-toggle:checked + .sidebar .sidebar-brand h2 span:last-child{
    margin-top:.1rem;
    font-size: 1.7rem;
    display: inline-block;
    padding-left: 2.5rem;
    display: flex;
    justify-content: center;
    text-align: center;
   

}

#nav-toggle:checked + .sidebar .sidebar-brand,
#nav-toggle:checked + .sidebar li
{
   padding-left:.5rem;
   text-align: center;

}

#nav-toggle:checked + .sidebar li a
{
   padding-left:0rem

}


#nav-toggle:checked + .sidebar .sidebar-brand h2 span:last-child,
#nav-toggle:checked + .sidebar li a span:last-child
{
    display: none;

}


#nav-toggle:checked ~ .main-content
{
   margin-left:0px;

}

#nav-toggle:checked ~ .main-content header
{
   width: calc(100%);
   left:0px;

}



.main-content{

    transition: margin-left 300ms;
    margin-left: 255px;
    margin-top:2rem

}

header{
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding-top: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    box-shadow: 2px 4px 5px rgba(0,0,0,0.2);
    position: fixed;
    left: 255px;
    width: calc(100% - 255px);
    top:0;
    z-index: 100;
    transition: width 300ms;
    height: 3.2rem;
}

header h1{
    font-size: .5rem;
}

header h2{
    color:#222;
    font-size: 1.5rem;

}

header h4{
    font-size: .8rem;
    padding-bottom: 0rem;
}

header .user-wrapper small{
    font-size: .8rem;
    padding-top: 0rem;
    margin-bottom: .1rem;
}

header label span{
    font-size: 1.7rem;
    padding-right: 1rem;;
}
.search-wrapper{
    border: 1px solid #ccc;
    border-radius: 30px;
    height: 50px;
    display: flex;
    align-items: center;
    overflow-x: hidden;
}

.search-wrapper span{
    display: inline-block;
    padding:0rem 1rem;
    font-size: 1.5rem;
}

.search-wrapper input{
    height: 100%;
    padding: .5rem;
    border:none;
    outline:none;
}

.search-wrapper input{
    height: 100%;
    padding: .5rem;
    border: none;
    outline: none;


}

.user-wrapper{
    display: flex;
    align-items: center;
}

.user-wrapper img{
    border-radius: 50%;
    margin-right: 1rem;
}


.user-wrapper small {
    display: inline-block;
    color:var(--text-grey);
   

}

main{

    padding: 2rem 1.5rem;
    background: #f1f5f9;
    height: 100%;
    min-height: calc(100vh - 90px);


}

.cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap:2rem;
    margin-top: 1rem;
}

.card-single{
    display: flex;
    justify-content: space-between;
    background: #fff;
    box-shadow: 2px 4px 5px rgba(0,0,0,0.2);
    
   
   
}


.card-single h1{
    padding-left:2rem;
    padding-top:1.5rem
}


.card-single  span{
    padding-left:2rem;
    
}

.card-single  div:first-child span{
    font-size:.7rem
    
}


.card-single div:last-child span{
    font-size: 7rem;
    color:var(--main-color);
    opacity: 8%;
    
}

.card-single div:first-child span{
    color:var(--text-grey);

}

.card-single:last-child{
    background: var(--main-color);
}

.card-single:first-child{
    background: #fff;
    
}



.card-single:last-child h1,
.card-single:last-child div:first-child span,
.card-single:last-child div:last-child span{
    color:#fff
}

.recent-grid{
    margin-top: 3.5rem;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 100%;
    margin-top:1%;
    margin-left: 10%;
    margin-right:10%;
    box-shadow: 2px 6px 7px rgba(0,0,0,0.2);;
   
}

.recent-grid-main{
    margin-top: 3%;
    grid-template-columns: 100%;
    border: 1px solid lightgray;

   
}


.recent-grid-add-campaign{
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 100% auto;
   
}

.card{
    background: #fff;
    border-radius: 5px;
}

.card-header,
.card-body{
    padding:1rem;
}

.card-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
  
}

.card-header button{
    background: var(--main-color);
    border-radius: 10px;
    color:#fff;
    font-size: .8rem;
    padding: .5rem 1rem;
    border:1px solid var(--main-color);

}

table{
    border-collapse: collapse;

}

thead tr {
    border-top:1px solid #f0f0f0;
    border-bottom:2px solid #f0f0f0;
}

table td {
    padding:15px;
}

table td{
    border-bottom: rgb(228, 228, 228);
   
}

.inputForm{
    padding:5px;
}


.card-body form{
    position: relative;
    min-height: 490px;
    background-color: #ffff;
}

.card-body form .details{
    margin-top: 10px;
}

.card-body form .title{
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 10px;
    margin: 6px 0;
}


.card-body form .fields{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    
}

.card-body form .fields_second{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    
}

form .fields .input-field{
    display: flex;
    width: calc(100%/3 - 15px);
    flex-direction: column;
    margin-top: 15px;
}

form .fields_second .input-field{
    display: flex;
    width: calc(100%/2 - 5px);
    flex-direction: column;
    justify-content: space-between;
    margin-top: 15px;
}


.card-body form .fields{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    
}


.input-field label{
    font-size: 12px;
    font-weight:bolder;
    color: #1D2231;
    padding-left: 10px;
}



.input-field select {
    outline: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    border:1px solid #aaa;
    padding:0 15px;
    height: 42px;
    margin:8px;

}


.input-field input:is(:focus, :valid){
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}


.btnNext {
   background-color:  var(--main-color);
   padding:10px;
   color:white;
   border:none;
}


.cdate{
    margin:10px 10px 10px 10px; display:flex; flex-direction: row; background-color: aliceblue; padding: 20px
}

.textBoxPadding{
    margin-left:6px;
}


.date-picker-wrapper {
    display: flex !important;
    flex-direction: column !important;
}


.campaignHeaderWrapper{
    display: flex;
    justify-content: center;
}


.campaignHeader{
    width:80%; justify-content: center; padding:10px; display:flex; background-color:aliceblue; flex-direction:row; cursor:pointer
}

.campaignHeaderInnerLeft{
    width: 70%;
}

.campaignHeaderInnerRight{
    width: 18%;
}

.subCampaignHeader{
    display: flex;
    justify-content: center;
    margin-top: .5rem;
 }
 
.subCampaignInner{

    width: 78%; padding: 10px; display: flex; justify-content: center; background-color: aliceblue; flex-direction: row; align-items: center; align-content: center;
}

.calendarWrapper{

    padding: 2rem; margin-top: 1rem;  background-color: aliceblue; display: flex; justify-content: center;
}

.calendarWrapperPeriods{

    display: flex; flex-direction: column; padding: 2rem; margin-top: 1rem;  background-color: aliceblue;
}

.react-datepicker__day--highlighted{
    background-color: #4169e1 !important;
    color:white !important;
}

.react-datepicker__day--excluded{
    background-color: red !important;
    color:'white' !important;
}



.react-datepicker{
    display: flex !important;
   padding-top: 20px;
   border:none !important;

}
.react-datepicker__current-month
{
    color:white !important;
}
.react-datepicker__day-name{
    color:white !important;
}
.react-datepicker__header{
   
    background-color:#2950C5 !important;
    margin: 5px;
    border: 1px solid black;
    color: white !important;

}

.react-datepicker__input-container input{
    width: 250px !important;
    height: 50px !important;
    padding:10px;
}


.react-datepicker__day--in-range{
    background-color: #3253b4 !important;
    color:white !important;
}


.react-datepicker__day{
    margin:5px !important;
   
}

.react-datepicker__month-container{
  
    padding:2px;
    margin: 10px;
    height: 300px;
    border: 1px solid lightgray
}


.css-1q79v3g-MuiButtonBase-root-MuiChip-root  {
    color:#fff !important;
    background-color: #5a7ff1 !important ;
}

.periodHeader{

    background-color: rgb(217, 234, 246);  padding: 17px; text-align: left; margin-bottom: 1px; font-weight: 800;


   
}

.periodHeaderMonth{

    padding: 13px; height: 78px; background-color: rgb(164, 217, 255); margin-bottom: 1px; margin-left: 1px;
}

.periodHeaderTbl{

   width:'50px'; padding: '10px'; background-color: rgb(164, 217, 255);
}

.periodRow{
    padding:15px; margin:1px;   background-color: rgb(217, 234, 246); height:59px
  
}

.periodRowContinuous{
    padding:17px; margin:1px;   background-color: rgb(217, 234, 246); text-align: left;
  
}


.finalTblTr{
    background-color: #ffff;
    margin:.2rem;
  
}
.finalTblTd{
    width:20rem;
    border-right: 1px solid lightgrey;
}

.input-field input:is(:focus, :valid){
    box-shadow: none !important;
}

.highlighted-class{
    background-color: red !important;
}



.btnEnabled{
    background-color:  #5a7ff1 !important; color:white !important; margin-top:5px; font-size:12px; float: right;
}

.btnDisabled{
    background-color: rgb(214, 210, 210)!important; color:black; margin-top:5px; font-size:12px; float: right;
}

nav ul ul{
    display:none;
}

nav ul li:hover > ul {
		display: block;
}

.listingBox{
    display:flex; flex-direction:column; align-content:'center';
    border-radius:50;
    margin-top:20;
    background-color:'aliceblue';
    padding:30;
    font-size:15;
    box-shadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
}

.btnValidateOn{
    margin-left:10; background-color:#4169e1; color:white; align-content:'center'; border: none; padding:8px 15px 8px 15px;border-radius: 4px;  margin-left:5px; font-weight: 600;box-shadow: 12px 10px 15px rgba(0, 0, 0, 0.1);
}
   
.btnValidateOff{
    margin-left:10; background-color:#caccd1; color:black; align-content:'center'; border: none; padding:8px 15px 8px 15px;border-radius: 4px; margin-left:5px;font-weight: 600; box-shadow: 12px 10px 15px rgba(0, 0, 0, 0.1);
}

.inputDisable{
    width:'50%';  padding:'5px'; border:'1px solid white'; background-color:'#e2e2e2'
}

.inputEnable{
width:'50%';  padding:'5px'; border:'none'
}

.dt_box{
    width:25%; margin:20px; padding:20px; background-color:white; justify-content: space-around; border-radius: 40px; font-weight: 500; font-size: small;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}

.dt_box_child{
    display:'flex !important'; flex-direction:'column !important'; justify-content:'space-between !important',;
}
.dt_box_label{font-weight: 300;}

@media only screen  and (max-width:960px){


    .recent-grid{
        grid-template-columns: 100%
    }
	
	
    form .fields .input-field{
        display: flex;
        width:100%;
        flex-direction: column;
        margin-top: 15px;
    }

    .cdate{

        margin:50px 10px 10px 10px; display:flex; flex-direction: column; justify-content: center; align-items: center;
    }

    .campaignHeader{
        width:60%; justify-content: center; padding:10px; display:flex; background-color:aliceblue; flex-direction:column; cursor:pointer
    }

    .campaignHeaderInnerLeft{
        width: 100%;
        text-align: center;
        padding:10px;
        font-size: .5rem
    }
    
    
    .campaignHeaderInnerRight{
        width: 100%;
        text-align: center;
        padding:5px;
        font-size: .5rem
    }

    .subCampaignInner{

      font-size: .5rem;  width: 60%; padding: 10px; display: flex; justify-content: center; background-color: aliceblue; flex-direction: column; align-items: center; align-content: center;
    }


.calendarWrapper{
 padding: 2rem; background-color: aliceblue; margin-left: 10rem; margin-right: 10rem; margin-top: 5rem;
}


.css-iqd1hh-MuiButtonBase-root-MuiChip-root {
    color:#fff !important;
    background-color: #5a7ff1 !important;
}

.periodHeader{

    background-color: rgb(217, 234, 246);  padding: 15px; text-align: center; margin-bottom: 1px; font-weight: 800;


   
}

.periodHeaderMonth{

    padding: 13px; height: 78px; background-color: rgb(164, 217, 255); margin-bottom: 1px; margin-left: 1px;
}

.periodRow{
    padding:15px; margin:1px;   background-color: rgb(217, 234, 246); 
  
}

.finalTblTr{
    background-color: #ffff;
    margin:.2rem;
  
}
.finalTblTd{
    width:20rem;
    border-right: 1px solid lightgrey;
}

.inputForm{	
    padding:5px;	
}

.MuiAutocomplete-tag .MuiAutocomplete-tagSizeMedium .MuiButtonBase-root .MuiChip-root .MuiChip-filled .MuiChip-sizeMedium .MuiChip-colorDefault .MuiChip-deletable .MuiChip-deletableColorDefault .MuiChip-filledDefault {	
    color:#fff !important;
    background-color: #5a7ff1 !important ;
}	

.periodHeader{	
    background-color: rgb(217, 234, 246);  padding: 15px; text-align: center; margin-bottom: 1px; font-weight: 800;	
   	
}



.table { width: 100%; }
.table td, .table th { overflow-wrap: anywhere; }





}

.styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    width: 100%;
 
}

.styled-table thead tr {
    background-color: #5a7ff1 ;
    color: #ffffff;
    text-align: left;
}


.styled-table th,
.styled-table td {
    padding: 30px 25px;
   
  
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.styled-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

  


@media only screen  and (max-width:768px){


    form .fields .input-field{
        display: flex;
        width:100%;
        flex-direction: column;
        margin-top: 15px;
    }
    

    .cards{
        grid-template-columns: 100%;
    }

    .recent-grid{
        grid-template-columns: 100%;
    }

    .search-wrapper{
        display: none;
    }

    .sidebar{
        left: -100% !important;
        top:5% !important;

      
    }



    header h2{
        font-size: 1.1rem;
		
    }

    .main-content{
        width: 100%;
        margin-left: 0rem;
    }

    header {
        width: 100% !important;
        left:0 !important;
    }
    
    #nav-toggle:checked + .sidebar{
        display: block;
        left: 0 !important;
        z-index: 100;
        width: 255px;
    }

    #nav-toggle:checked + .sidebar:hover{
        width: 255px;
        z-index: 200;
    }

    #nav-toggle:checked + .sidebar .sidebar-brand,
    #nav-toggle:checked + .sidebar li
    {
    padding-left:2rem;
    text-align: left;

    }

    #nav-toggle:checked + .sidebar li a
    {
    padding-left:1rem

    }

    .react-datepicker-popper {
        z-index: 9999 !important;
    }

    #nav-toggle:checked + .sidebar .sidebar-brand h2 span:last-child,
    #nav-toggle:checked + .sidebar li a span:last-child
    {
        display: inline;

    }

    .cdate{

        margin:50px 10px 10px 10px; display:flex; flex-direction: column; justify-content: center; align-items: center;

    }
}




@media only screen  and (max-width:560px){

    .cards{
        grid-template-columns: 100%;
    }


 header h2{
		display:flex;
        font-size: 1.1rem;
		justify-content:center;
		text-align:center
		
    }

    .cdate{

        margin:50px 10px 10px 10px; display:flex; flex-direction: column; justify-content: center; align-items: center;;

    }

  
}
